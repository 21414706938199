<template>
  <div>
    <v-card class="mx-auto" max-width="500" tile>
      <v-toolbar color="var(--cust-red)" dark>
        <v-toolbar-title>{{ topic }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <v-text-field dense class="align-end" label="Search" ></v-text-field> -->
      </v-toolbar>
      <v-list dense>
        <v-list-item-group single color="primary">
          <v-list-item
            @click="selected = item"
            v-for="(item, i) in list"
            :key="i"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-flex>
                <v-list-item-content>
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-list-item-title
                        ><h2>{{ item.nice_name }}</h2>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.account_number }}
                      </v-list-item-subtitle>
                    </div>
                  </div>
                  <!-- <v-list-item-subtitle
                    ><h4>{{ i }}</h4></v-list-item-subtitle
                  > -->
                </v-list-item-content>
                <v-divider></v-divider>
              </v-flex>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions class="text-center">
        <v-btn @click="confirm" class="cust-btn-color"> {{$t('general.confirm')}} </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ListSelect',
  props: {
    list: {
      default: null
    },
    topic: {
      default: null
    }
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    confirm() {
      this.$emit('SucessClick', this.selected)
    }
  }
}
</script>

<style>
</style>