<template>
  <div class="py-2 px-2">
    <v-row class="mx-1 elevation-5 mb-10">
      <v-col class="col-12 align-center">
        <v-layout justify-space-between align-center mb-2>
          <v-flex shrink>{{ $t('selectPayment.totalProductCost') }} :</v-flex>
          <v-flex shrink class="txt-bigger font-weight-light darkblue-color">

            {{ toCurrencyLable(currency.currency) }}
            {{ formatNumber(subTotal ,2) }}
          </v-flex>
        </v-layout>
        <v-layout justify-space-between align-center mb-2>
          <v-flex shrink>{{ $t('selectPayment.shippingFee') }} : </v-flex>
          <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
            {{
              (deliveryCost === 0 || !deliveryCost) ? 'FREE' : toCurrencyLable(currency.currency)
            }}
            {{
              (deliveryCost === 0 || !deliveryCost) ? '' :
                formatNumber(
                  specificExchangeRate(deliveryCost, currency.currency), 2
                )
            }}
          </v-flex>
        </v-layout>
        <!-- <v-layout justify-space-between align-center mb-2>
          <v-flex shrink>VAT : </v-flex>
          <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
            {{ formatNumber(vat * 100, 2) }} %
          </v-flex>
        </v-layout> -->
        <v-row justify-space-between align-center style="background: #f5f5f5">
          <v-col shrink class="font-weight-bold"
            >{{ $t('selectPayment.totalPayment') }} :</v-col
          >
          <v-col shrink class="font-weight-bold txt-head text-right cust-color">
            {{ toCurrencyLable(currency.currency) }}
            {{ formatNumber(totalIncludedTax, 2) }}
            <br />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="txt-head">
      <v-icon class="mr-3"> mdi-credit-card-outline </v-icon
      >{{ $t('selectPayment.choosePayment') }}
    </div>
    <v-expansion-panels style="display: contents">
      <v-radio-group v-model="payment">
        <v-expansion-panel @click="payment = 'transfer'" class="mb-3">
          <v-expansion-panel-header style="background: #e5e5e5" expand-icon="">
            <v-row class="text-center" justify="space-between" align="center">
              <v-col cols="1" md="2">
                <v-radio value="transfer"></v-radio>
              </v-col>
              <v-col cols="11" md="4">
                <v-img
                  :width="$vuetify.breakpoint.smAndDown ? '50%' : '70%'"
                  :src="require(`../../assets/img/transfer.png`)"
                  class="pointer"
                ></v-img>
              </v-col>
              <v-col cols="6" md="3">
                {{ $t('selectPayment.payWithTransfer') }}
              </v-col>
              <v-col class="font-weight-bold" cols="6" md="3"> KRW, THB </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <BankTransfer
              ref="bankselect"
              :deliveryCost="deliveryCost"
              :cartItems="cartItems"
            ></BankTransfer>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel @click="payment = 'paypal'" class="mb-3">
          <v-expansion-panel-header style="background: #e5e5e5" expand-icon="">
            <v-row class="text-center" justify="space-between" align="center">
              <v-col cols="1" md="2">
                <v-radio value="paypal"></v-radio>
              </v-col>
              <v-col cols="11" md="4">
                <v-img
                  :width="$vuetify.breakpoint.smAndDown ? '50%' : '70%'"
                  :src="require(`../../assets/img/paypal.png`)"
                  class="pointer"
                ></v-img>
              </v-col>
              <v-col cols="6" md="3">
                {{ $t('selectPayment.payWithPaypal') }}
              </v-col>
              <v-col cols="6" class="font-weight-bold" md="3"> USD, THB </v-col>
            </v-row>
          </v-expansion-panel-header>
        </v-expansion-panel>

        <v-expansion-panel @click="payment = 'omise'">
          <v-expansion-panel-header style="background: #e5e5e5" expand-icon="">
            <v-row class="text-center" align="center">
              <v-col cols="1" md="2">
                <v-radio value="omise"></v-radio>
              </v-col>
              <v-col cols="11" md="4">
                <v-img
                  :width="$vuetify.breakpoint.smAndDown ? '50%' : '70%'"
                  :src="require('../../assets/img/omise.png')"
                  class="pointer"
                ></v-img>
              </v-col>
              <v-col cols="6" md="3">
                {{ $t('selectPayment.payWithCreditCard') }}
              </v-col>
              <v-col cols="6" class="font-weight-bold" md="3"> USD, THB </v-col>
            </v-row>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-radio-group>
    </v-expansion-panels>
    <div class="row mt-5">
      <div class="col-md-3 col-12 offset-md-3">
        <v-btn @click="$emit('onBack')" class="cust-border-btn-color" block>
          <v-icon small class="mr-2">chevron_left</v-icon>{{ $t('general.back') }}
        </v-btn>
      </div>
      <div class="col-md-3 col-12">
        <v-btn
          @click="$router.push({ name: 'Cart' })"
          class="cust-border-btn-color"
          block
        >
          {{ $t('general.cancel') }}
        </v-btn>
      </div>
      <div class="col-md-3 col-12">
        <v-btn
          :disabled="!payment"
          @click="confirm"
          class="cust-btn-color"
          block
        >
          {{ $t('general.confirm') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import BankTransfer from '../../components/Payment/BankTransfer'
import { formatNumber } from '@/plugins/global-funcs'
import { currency_symbol } from '../../config'
import { mapState } from 'vuex'
export default {
  components: {
    BankTransfer
  },
  props: {
    addressDelivery: {
      default: function () {
        return []
      }
    },
    addressBilling: {
      default: function () {
        return []
      }
    },
    cartItems: {
      default: function () {
        return []
      }
    },
    deliveryCost: {
      default: 0
    },
    tax: {
      default: 0
    }
  },
  data() {
    return {
      formatNumber: formatNumber,
      currency_symbol: currency_symbol,
      payment: null
    }
  },
  computed: {
    ...mapState({
      currency: (state) => state.Currency.current,
      inCart: (state) => state.Cart.inCart,
      cartDetail: (state) => state.Cart.cartDetail,
      exchangeRate: (state) => state.Currency.exchangeRate,
      transfer: (state) => state.Payment.transfer,
      vat: (state) => state.Payment.vat
    }),
    subTotal: function () {
      let sum = 0.0
      for (const x of this.cartItems) {
        let price = this.specificExchangeRate(
          x.price_per_piece,
          this.currency.currency
        )
        sum += parseFloat(price * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0 ? sum : '-'
    },
    totalIncludedTax: function () {
      let total =
        parseFloat(this.subTotal) +
        parseFloat(
          this.specificExchangeRate(this.deliveryCost, this.currency.currency)
        )
      return parseFloat(total) || parseFloat(total) == 0 ? total : '-'
    }
  },
  methods: {
    confirm() {
      if (this.payment === 'transfer') {
        if (this.$refs.bankselect.account !== null) {
          this.$emit('onNext', this.payment)
        }
      } else {
        this.$emit('onNext', this.payment)
      }
    },
    toCurrencyLable(currency) {
      return this.currency_symbol[currency]
    },
    toExchangeRate(thb_price) {
      return this.currency.sell_rate * thb_price
    },
    specificExchangeRate(thb_price, currency) {
      if(currency === 'USD') {
        return Math.ceil((this.exchangeRate[currency].sell_rate * thb_price)*10)/10
      }
      else return Math.ceil(this.exchangeRate[currency].sell_rate * thb_price);
      // return this.exchangeRate[currency].sell_rate * thb_price;
    },
    increaseItem(item) {
      this.$emit('increaseItem', item)
    },
    decreaseItem(item) {
      this.$emit('decreaseItem', item)
    },
    removeItem(item) {
      this.$emit('removeItem', item)
    }
  }
}
</script>

<style>
</style>