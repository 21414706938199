<template>
  <div style="background: #f5f5f5">
    <v-form
      v-if="currency.currency === 'THB' || currency.currency ==='USD'"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5"> {{ $t('transferInfo.date') }} </v-col>
        <v-col class="text-right font-weight-bold">
          <v-dialog
            ref="dialog_date"
            v-model="dateModal"
            :return-value.sync="pay.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                class="centered-input"
                :value="formatDate"
                append-icon="mdi-calendar"
                readonly
                :rules="rules"
                required
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="pay.date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateModal = false">
                {{$t('general.cancel')}}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog_date.save(pay.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5"> {{ $t('transferInfo.time') }} </v-col>
        <v-col class="text-right font-weight-bold">
          <v-dialog
            ref="dialog_time"
            v-model="timeModal"
            :return-value.sync="pay.time"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="pay.time"
                :rules="rules"
                append-icon="mdi-clock-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                required
                class="centered-input"
              ></v-text-field>
            </template>
            <v-time-picker v-if="timeModal" v-model="pay.time" full-width>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="timeModal = false">
                {{$t('general.cancel')}}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog_time.save(pay.time)"
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
      </div>
      <!-- <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5">Transfer from Bank</v-col>
        <v-col class="text-right font-weight-bold">
          <div @click="openBankList(true)" class="pointer">
            <div v-if="!pay.bankFrom">
              Select Bank <v-icon>mdi-chevron-right</v-icon>
            </div>
            <div v-else>{{ pay.bankFrom.nice_name }}</div>
          </div>
        </v-col>
      </div> -->
      <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5">{{ $t('transferInfo.transferTo') }}</v-col>
        <v-col class="text-right font-weight-bold">
          {{ transferTo.nice_name }}<br />
          {{ transferTo.account_name }}<br />
          {{ transferTo.account_number }}
        </v-col>
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5">{{ $t('transferInfo.amountTransferred') }}</v-col>
        <v-col class="text-right font-weight-bold">
          <v-text-field
            type="number"
            v-model="pay.amount"
            :rules="rules"
            dense
            class="centered-input"
            required
          ></v-text-field>
        </v-col>
      </div>
      <!-- <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5">
          Transfer from Account No. (last 4 digits) <br />
          <div class="txt-secondary">
            Simple of Account No. (last 4 digits) : XXX-X-XX123-4
          </div></v-col
        >
        <v-col class="text-right font-weight-bold">
          <v-text-field
            type="number"
            maxlength="4"
            v-model="pay.lastDigit"
            :rules="rules.concat(limitRules)"
            dense
            class="centered-input"
            required
          ></v-text-field>
        </v-col>
      </div> -->

      <v-dialog v-model="listModal" max-width="500">
        <div v-if="listModal" style="background-color: white">
          <ListSelect
            @SucessClick="SucessClick"
            :list="bankList"
            topic="Select Bank"
          ></ListSelect>
        </div>
      </v-dialog>
    </v-form>

    <v-form
      v-else
      ref="formKR"
      v-model="valid"
      lazy-validation
    >
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5"> 입금일자 </v-col>
        <v-col class="text-right font-weight-bold">
          <v-dialog
            ref="dialog_date"
            v-model="dateModal"
            :return-value.sync="pay.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                class="centered-input"
                :value="formatDate"
                append-icon="mdi-calendar"
                readonly
                :rules="rules"
                required
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="pay.date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateModal = false">
                {{$t('general.cancel')}}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog_date.save(pay.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5">입금 계좌</v-col>
        <v-col class="text-right font-weight-bold">
          {{ transferTo.nice_name }}<br />
          {{ transferTo.account_name }}<br />
          {{ transferTo.account_number }}
        </v-col>
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5">입금액</v-col>
        <v-col class="text-right font-weight-bold">
          <v-text-field
            type="number"
            v-model="pay.amount"
            :rules="rules"
            dense
            class="centered-input"
            required
          ></v-text-field>
        </v-col>
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between align-center">
        <v-col class="pl-5">입금자명</v-col>
        <v-col class="text-right font-weight-bold">
          <v-text-field
            type="text"
            v-model="pay.transferBy"
            :rules="rules"
            dense
            class="centered-input"
            required
          ></v-text-field>
        </v-col>
      </div>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment'
import ListSelect from './ListSelect'
import { mapState } from 'vuex'
export default {
  name: 'TransferInfo',
  components: {
    ListSelect
  },
  computed: {
    ...mapState({
      currency: (state) => state.Currency.current,
      bankFrom: (state) => state.Payment.bank,
      bankTo: (state) => state.Payment.bankHost
    }),
    formatDate(date) {
      return this.pay.date ? moment(this.pay.date).format('DD/MM/YYYY') : ''
    }
  },
  props: {
    transferTo: {
      default: null
    }
  },
  data() {
    return {
      pay: {
        bankFrom: '',
        bankTo: '',
        date: new Date().toISOString().substr(0, 10),
        time: new Date().getHours() + ':' + new Date().getMinutes(),
        amount: '',
        lastDigit: '',
        transferBy: ''
      },
      bankList: {},
      valid: false,
      rules: [(v) => !!v || 'This Field is required'],
      limitRules: [(v) => v.length <= 4 || 'Max 4 number'],
      dateModal: false,
      listModal: false,
      timeModal: false,
      isTransfer: false
    }
  },
  methods: {
    validate() {
      if (this.currency.currency === 'KRW') {
        if (this.$refs.formKR.validate()) {
          return this.pay
        } else {
          return false
        }
      } else {
        if (this.$refs.form.validate()) {
          return this.pay
        } else {
          return false
        }
      }
    },
    openBankList(isTransfer) {
      this.bankList = isTransfer ? this.bankFrom : this.bankTo
      this.isTransfer = isTransfer
      this.listModal = true
    },
    SucessClick($event) {
      this.listModal = false
      if (this.isTransfer) this.pay.bankFrom = $event
      else this.pay.bankTo = $event
    }
  }
}
</script>

<style>
</style>