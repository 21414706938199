<template>
  <div>
    <v-progress-linear
      v-if="imgLoading"
      indeterminate
      color="green"
    ></v-progress-linear>
    <div
      type="file"
      class="drop pa-5"
      :class="getClasses"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
      @click="$refs.file.click()"
      @change="drop"
      v-if="!imgLoading"
    >
      <input type="file" ref="file" style="display: none" accept="image/*" />

      <draggable
        :list="imageSource"
        @start="dragging = true"
        @end="dragging = false"
        class="row"
        v-if="imageSource.length !== 0"
      >
        <div class="text-center col-12" v-for="(item, index) in imageSource" :key="index">
          <v-hover v-slot="{ hover }">
            <v-img class="img-drop mx-auto" :src="item">
              <div
                v-if="hover || showHoverOnMobile"
                class="animate__animated animate__bounceIn pb-3 flex-center-row fill-height align-end"
                @click.stop
              >
                <v-btn
                  class="mb-2 mr-2"
                  elevation="0"
                  min-width="0"
                  small
                  @click="removeImg(index)"
                >
                  <span>REMOVE</span>
                </v-btn>
              </div>
            </v-img>
          </v-hover>
        </div>
      </draggable>
      <h1 v-if="wrongFile">Wrong file type</h1>
      <div class="fill-height text-center">
        <v-img
          v-if="imageSource.length == 0 && !wrongFile"
          :src="require(`../../assets/img/cloud-computing.png`)"
          height="150"
          contain
          class="pointer"
        >
        </v-img>
      </div>
    </div>
  </div>
</template>



<script>
import draggable from 'vuedraggable'
// import Service from "../../services";
// import { api_domain } from "../../config";
export default {
  name: 'DragAndDrop',
  props: {
    imgList: {
      type: Array
    },
    locationType: {
      type: String
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      isDragging: false,
      dragging: false,
      wrongFile: false,
      imgLoading: false,
      imageSource: [],
      imageFile: [],
      showHoverOnMobile: false
    }
  },
  computed: {
    getClasses() {
      return { isDragging: this.isDragging }
    }
  },
  created() {
    this.imageSource = this.imgList
  },
  methods: {
    dragOver() {
      this.isDragging = true
    },
    dragLeave() {
      this.isDragging = false
    },
    removeImg(index) {
      this.imageSource.splice(index, 1)
      this.$emit('updateImgProduct', this.imageSource)
    },
    async drop(e) {
      let files = e.target.files || e.dataTransfer.files
      console.log(files)
      this.wrongFile = false
      this.imgLoading = true
      // allows only 1 file
      if (files.length === 1) {
        let file = files[0]

        if (file.type.indexOf('image/') >= 0) {
          let form = new FormData()
          form.append('type', this.locationType)
          form.append('image', file)
          let imgURL = URL.createObjectURL(file)
          //let result = await Service.upload.uploadImage(form);
          //let imgURL = api_domain + result.data;
          this.imageSource = []
          this.imageSource.push(imgURL)
          this.imageFile.push(file)
          this.$emit('updateImgProduct', this.imageFile)
          this.isDragging = false
          this.imgLoading = false
        }
      } else {
        this.isDragging = false
        this.imgLoading = false
      }
    }
  }
}
</script>



<style scoped>
.drop {
  width: 100%;
  background-color: #f5f5f5;
  border: 10px solid #f5f5f5;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}
.isDragging {
  background-color: #999;
  border-color: #fff;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.img-drop {
  max-width: 500px;
  max-height: 500px;
}
</style>