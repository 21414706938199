<template>
  <div>
    <h3>
      <v-icon> mdi-credit-card-outline </v-icon>
      {{ $t('selectPayment.confirmPayment') }}
    </h3>
    <v-container class="mt-10">
      <v-row style="background: #f5f5f5" justify="space-between">
        <v-col class="text-left">{{ $t('selectPayment.totalPayment') }}</v-col>
        <v-col class="text-right red--text"
          >{{
            currencySymbol[
              transfer.currency === 'USD' ? 'THB' : transfer.currency
            ]
          }}
          {{ transfer.price }}</v-col
        >
      </v-row>
    </v-container>
    <div
      v-if="currency.currency !== 'KRW'"
      style="background: #f5f5f5"
      class="mt-3"
    >
      <v-col class="text-left">{{ $t('transferInfo.uploadReceipt') }}</v-col>
      <DragAndDrop
        locationType="brand"
        :imgList="[]"
        @updateImgProduct="billImage = $event"
      ></DragAndDrop>
      <v-divider></v-divider>
      <v-row class="py-2 px-10 align-center d-flex">
        <v-col md="1" cols="6">
          <v-img
            alt="upload-icon"
            :src="require('../../assets/img/bill_example.png')"
            contain
          ></v-img>
        </v-col>
        <v-col md="4" cols="6">{{
          $t('transferInfo.samplePhotoOfReceipt')
        }}</v-col>
      </v-row>
    </div>
    <div class="mt-5">
      <TransferInfo ref="transferDate" :transferTo="transfer"></TransferInfo>
    </div>
    <div class="row py-5">
      <v-col offset="6">
        <v-btn @click="$emit('onBackAddress')" class="cust-border-btn-color" block>
          <v-icon small class="mr-2">chevron_left</v-icon
          >{{ $t('general.back') }}
        </v-btn>
      </v-col>
      <v-col >
        <v-btn @click="cancel" block class="cust-border-btn-color mr-2">
          {{ $t('general.cancel') }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn @click="save" block class="cust-btn-color">
          {{ $t('general.confirm') }}
        </v-btn>
      </v-col>
    </div>
  </div>
</template>
<script>
import Service from '../../services'
import TransferInfo from './TransferInfo'
import DragAndDrop from '../input/DragAndDrop'
import { mapState } from 'vuex'
import { currency_symbol } from '../../config'
export default {
  name: 'UploadSlip',
  metaInfo: {
    title: `Thai bike parts`,
    titleTemplate: 'Upload Slip | %s'
  },
  data() {
    return {
      currencySymbol: currency_symbol,
      billImage: []
    }
  },
  computed: {
    ...mapState({
      accessToken: (state) => state.Auth.userDetail.access_token,
      cartDetail: (state) => state.Cart.cartDetail,
      address: (state) => state.Address.userAddress,
      useAddressID: (state) => state.Address.useAddressID,
      transfer: (state) => state.Payment.transfer,
      exchangeRate: (state) => state.Currency.exchangeRate,
      vat: (state) => state.Payment.vat,
      deliveryCost: (state) => state.Address.shippingCost,
      currency: (state) => state.Currency.current
    })
  },
  props: {
    total: {
      default: 0
    }
  },
  components: {
    TransferInfo,
    DragAndDrop
  },
  methods: {
    cancel() {
      this.$emit('onBack')
    },
    save() {
      let form = new FormData()
      let pay = this.$refs.transferDate.validate()
      let valid = this.$refs.transferDate.valid
      if (pay && valid) {
        pay.bankFrom = pay.bankFrom ? pay.bankFrom.nice_name : ''
        pay.bankTo = this.transfer.nice_name
        pay.cart_id = this.cartDetail.id
        pay.currency = this.transfer.currency
        pay.reference_number = this.cartDetail.reference_number
        pay.user_address_id = this.useAddressID
        pay.access_token = this.accessToken
        pay.vat = this.vat
        pay.sell_rate = this.exchangeRate[pay.currency].sell_rate
        pay.shipping_price = this.deliveryCost
        form.append('data', JSON.stringify(pay))
        form.append('image', this.billImage[0])
        Service.payment.saveTransAction(form).then((res) => {
          if (res.status == 200) {
            this.$router.push({
              name: 'PaymentResult',
              query: { status: 'completed', order: res.data.id }
            })
          } else {
            this.$router.push({
              name: 'PaymentResult',
              query: { status: 'canceled' }
            })
          }
        })
        this.$store.commit('Payment/setTransfer', pay)
      }
    }
  }
}
</script>

<style>
</style>