<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <form id="checkoutForm">
      <input type="hidden" name="omiseToken" />
      <input type="hidden" name="omiseSource" />
      <button type="submit" id="checkoutButton"></button>
    </form>
  </div>
</template>

<script>
import Service from '../../services'
import { mapState } from 'vuex'
import { omise_public_key } from '../../config'
import Loading from '../../components/Loading'
export default {
  mounted() {
    this.initOmise()
  },
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false
    }
  },
  props: {
    cartItems: {
      default: function () {
        return []
      }
    },
    deliveryCost: {
      default: 0
    },
    tax: {
      default: 0
    }
  },
  computed: {
    ...mapState({
      currency: (state) => state.Currency.current,
      exchangeRate: (state) => state.Currency.exchangeRate,
      accessToken: (state) => state.Auth.userDetail.access_token,
      cartDetail: (state) => state.Cart.cartDetail,
      useAddressID: (state) => state.Address.useAddressID,
      address: (state) => state.Address.userAddress,
      vat: (state) => state.Payment.vat
    }),
    subTotal: function () {
      let sum = 0.0
      for (const x of this.cartItems) {
        let price = this.specificExchangeRate(x.price_per_piece, this.currency.currency)
        sum += parseFloat(price * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0 ? sum : '-'
    },
    totalIncludedTax: function () {
      let total =
        parseFloat(this.subTotal) +
        parseFloat(
          this.specificExchangeRate(this.deliveryCost, this.currency.currency)
        )
      return parseFloat(total) || parseFloat(total) == 0 ? total : '-'
    }
  },
  methods: {
    addVAT(price) {
      return price + price * this.vat
    },
    specificExchangeRate(thb_price, currency) {
      if (currency === 'KRW') currency = 'USD'

      if(currency === 'USD') return Math.ceil((this.exchangeRate[currency].sell_rate * thb_price) * 10) / 10;
      else return Math.ceil(this.exchangeRate[currency].sell_rate * thb_price);
      // return this.exchangeRate[currency].sell_rate * thb_price;
    },
    toCurrencyLable(currency) {
      return currency === 'KRW' ? 'USD' : currency
    },
    initOmise() {
      //eslint-disable-next-line no-undef
      OmiseCard.configure({
        publicKey: omise_public_key
      })
      //eslint-disable-next-line no-undef
      OmiseCard.open({
        amount: this.totalIncludedTax * 100,
        frameLabel: 'Thai Bike Part',
        currency: this.toCurrencyLable(this.currency.currency),
        defaultPaymentMethod: 'credit_card',
        onCreateTokenSuccess: (nonce) => {
          if (nonce.startsWith('tokn_')) {
            this.isLoading = true
            Service.payment
              .omiseCharge({
                amount: this.totalIncludedTax * 100,
                currency: this.toCurrencyLable(this.currency.currency),
                token_id: nonce,
                data: {
                  reference_number: this.cartDetail.reference_number,
                  cart_id: this.cartDetail.id,
                  user_address_id: this.useAddressID,
                  access_token: this.accessToken,
                  vat: this.vat,
                  sell_rate:
                    this.exchangeRate[this.currency.currency].sell_rate,
                  shipping_price: this.deliveryCost
                }
              })
              .then((res) => {
                if (res.status === 200) {
                  this.isLoading = false
                  if (
                    res.data.message === 'pending' &&
                    res.data.authorize_uri
                  ) {
                    window.location.replace(res.data.authorize_uri)
                  } else if (res.data.message === 'successful') {
                    this.$router.push({
                      name: 'PaymentResult',
                      query: { status: 'completed', order:res.data.order_id }
                    })
                  }
                }
              })
          } else {
            this.$router.push({
              name: 'PaymentResult',
              query: { status: 'canceled' }
            })
          }
        },
        onFormClosed: () => {
          this.$emit('onBack')
        }
      })
    }
  }
}
</script>

<style>
</style>