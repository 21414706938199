<template>
  <div><Loading :loading="isLoading"></Loading></div>
</template>

<script>
import Service from '../../services'
import Loading from '../Loading'
import { mapState } from 'vuex'
export default {
  name: 'Paypal',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false
    }
  },
  props: {
    cartItems: {
      default: function () {
        return []
      }
    },
    deliveryCost: {
      default: 0
    },
    tax: {
      default: 0
    }
  },
  computed: {
    ...mapState({
      currency: (state) => state.Currency.current,
      exchangeRate: (state) => state.Currency.exchangeRate,
      accessToken: (state) => state.Auth.userDetail.access_token,
      cartDetail: (state) => state.Cart.cartDetail,
      useAddressID: (state) => state.Address.useAddressID,
      address: (state) => state.Address.userAddress,
      vat: (state) => state.Payment.vat
    }),
    subTotal: function () {
      let sum = 0.0
      for (const x of this.cartItems) {
        let price = this.specificExchangeRate(x.price_per_piece, this.currency.currency)
        sum += parseFloat(price * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0 ? sum : '-'
    },
    totalIncludedTax: function () {
      let total =
        parseFloat(this.subTotal) +
        parseFloat(
          this.specificExchangeRate(this.deliveryCost, this.currency.currency)
        )
      return parseFloat(total) || parseFloat(total) == 0 ? total : '-'
    }
  },
  methods: {
    addVAT(price) {
      return price + price * this.vat
    },
    paypalTransaction() {
      this.isLoading = true
      console.log('totalIncludedTax',this.totalIncludedTax)
      Service.payment
        .paypalTransaction({
          access_token: this.accessToken,
          total_price: this.totalIncludedTax,
          currency: this.toCurrencyLable(this.currency.currency),
          user_address_id: this.useAddressID,
          vat: this.vat,
          sell_rate:
            this.exchangeRate[this.toCurrencyLable(this.currency.currency)]
              .sell_rate,
          cart_id: this.cartDetail.id,
          reference_number: this.cartDetail.reference_number,
          shipping_price: this.deliveryCost
        })
        .then((res) => {
          if (res.status == 200) {
            window.location.href = res.data
          } else {
            this.$router.push({
              name: 'PaymentResult',
              query: { status: 'canceled' }
            })
          }
        })
    },
    specificExchangeRate(thb_price, currency) {
      if (currency === 'KRW') currency = 'USD'

      if(currency === 'USD') return Math.ceil((this.exchangeRate[currency].sell_rate * thb_price) * 10) / 10;
      else return Math.ceil(this.exchangeRate[currency].sell_rate * thb_price);
      // return this.exchangeRate[currency].sell_rate * thb_price;
    },
    toCurrencyLable(currency) {
      return currency === 'KRW' ? 'USD' : currency
    }
  },
  async mounted() {
    await this.paypalTransaction()
  }
}
</script>

<style>
</style>