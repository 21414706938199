<template>
  <v-radio-group v-model="account">
    <div v-if="transfer">
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-row justify="space-between">
            <v-radio
              @click="
                selectAccount(
                  transfer,
                  formatNumber(totalIncludedTax(transfer.currency), 2)
                )
              "
              class="ml-5"
              :value="transfer.id"
            ></v-radio>
            <div :style="{ background: transfer.color }"></div>
            <v-col class="font-weight-bold">{{ transfer.nice_name }} </v-col>
            <v-col class="text-right"
              ><h3 class="red--text">
                {{ toCurrencyLable(transfer.currency) }}
                {{ formatNumber(totalIncludedTax(transfer.currency), 2) }}
              </h3>
            </v-col>
          </v-row>
          <p class="mt-2">Account Name : {{ transfer.account_name }}</p>
          <p class="mt-2">
            Account Number :
            <span class="red--text text-md-h6">{{
              transfer.account_number
            }}</span>
          </p>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-radio-group>
</template>

<script>
import { mapState } from 'vuex'
import { formatNumber } from '@/plugins/global-funcs'
import { currency_symbol } from '../../config'
export default {
  name: 'BankTransfer',
  components: {
    //UploadSlip
  },
  computed: {
    ...mapState({
      bankHost: (state) => state.Payment.bankHost,
      transfer: (state) => state.Payment.transfer,
      currency: (state) => state.Currency.current,
      exchangeRate: (state) => state.Currency.exchangeRate,
      vat: (state) => state.Payment.vat
    })
  },
  created() {
    this.selectedBank = null
    this.$store.dispatch('Payment/onGetBankList').then(() => {
      if (this.currency.currency === 'USD') {
        this.selectedBank = this.bankHost.find((x) => x.currency === 'THB')
        this.selectedBank.price = this.formatNumber(
          this.totalIncludedTax(this.selectedBank.currency),
          2
        )
        this.$store.commit('Payment/setTransfer', this.selectedBank)
      } else {
        this.selectedBank = this.bankHost.find(
          (x) => x.currency === this.currency.currency
        )
        this.selectedBank.price = this.formatNumber(
          this.totalIncludedTax(this.selectedBank.currency),
          2
        )
          console.log(this.selectedBank)
        this.$store.commit('Payment/setTransfer', this.selectedBank)
      }
    })
  },
  props: {
    deliveryCost: {
      default: 0
    },
    cartItems: {
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      formatNumber: formatNumber,
      currency_symbol: currency_symbol,
      dialog: false,
      selectedBank: null,
      account: null
    }
  },
  methods: {
    toCurrencyLable(currency) {
      return this.currency_symbol[currency]
    },
    addVAT(price) {
      return price + price * this.vat
    },
    selectAccount(item, price) {
      item.price = price
      this.$store.commit('Payment/setTransfer', item)
    },
    SucessClick() {
      this.dialog = false
    },
    specificExchangeRate(thb_price, currency) {
      if(currency === 'USD') return Math.ceil((this.exchangeRate[currency].sell_rate * thb_price) * 10) / 10;
      else return Math.ceil(this.exchangeRate[currency].sell_rate * thb_price);
      // return this.exchangeRate[currency].sell_rate * thb_price;
    },
    subTotal: function (currency) {
      let sum = 0.0
      for (const x of this.cartItems) {
        let price = this.specificExchangeRate(x.price_per_piece, currency)

        sum += parseFloat(price * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0 ? sum : '-'
    },
    totalIncludedTax: function (currency) {
      let total =
        parseFloat(this.subTotal(currency)) +
        parseFloat(this.specificExchangeRate(this.deliveryCost, currency))
      return parseFloat(total) || parseFloat(total) == 0 ? total : '-'
    }
  }
}
</script>

<style>
</style>