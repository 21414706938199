<template>
  <div class="fill-height py-3 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <v-stepper v-model="step" style="box-shadow: none" alt-labels>
        <v-stepper-header style="box-shadow: none">
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 1 }"
            :complete="step > 1"
            step="1"
          >
            {{ $t('checkoutStep.login') }}
          </v-stepper-step>
          <v-divider class="border-color mt-10"></v-divider>
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 2 }"
            class="pointer"
            :complete="step > 2"
            step="2"
            @click="backToAddress"
          >
            {{ $t('checkoutStep.address') }}
          </v-stepper-step>
          <v-divider class="border-color mt-10"></v-divider>
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 3 }"
            step="3"
          >
            {{ $t('checkoutStep.selectPayment') }}
          </v-stepper-step>
          <v-divider class="border-color mt-10"></v-divider>
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 4 }"
            step="4"
          >
            {{ $t('checkoutStep.payment') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <Signin
              :only-facebook-login="true"
              :signup-email="signupEmail"
              @onChangeSignupEmail="signupEmail = $event"
              @onSignupSuccess="step = 2"
              :signup-detail="signupDetail"
              @setPassword="signupDetail.password = $event"
              @setFirstname="signupDetail.firstname = $event"
              @setLastname="signupDetail.lastname = $event"
              @onLogin="step = 2"
            ></Signin>
          </v-stepper-content>

          <v-stepper-content step="2">
            <Address @onNext="checkoutTopayment"></Address>
          </v-stepper-content>

          <v-stepper-content step="3">
            <SelectPayment
              v-if="step === 3"
              :cart-items="inCart"
              :delivery-cost="shippingCost"
              :addressDelivery="addressDelivery"
              :addressBilling="addressBilling"
              @onNext="payment"
              @onBack="backToAddress"
            ></SelectPayment>
          </v-stepper-content>
          <v-stepper-content step="4">
            <UploadSlip
              v-if="step === 4 && paymentSelect == 'transfer'"
              @onBack="step = 3"
              @onBackAddress="backToAddress"
            ></UploadSlip>
            <Omise
              v-if="step === 4 && paymentSelect == 'omise'"
              :delivery-cost="shippingCost"
              @onBack="step = 3"
              :cart-items="inCart"
            ></Omise>
            <Paypal
              v-if="step === 4 && paymentSelect == 'paypal'"
              :delivery-cost="shippingCost"
              :cart-items="inCart"
            ></Paypal>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import Signin from './StepPages/Signin'
import Address from '../components/Address/MainAddress.vue'
import Omise from '../components/Payment/Omise'
import Paypal from '../components/Payment/Paypal'
import { mapState } from 'vuex'
import ServiceAPI from '../services'
import Loading from '../components/Loading'
import UploadSlip from '../components/Payment/UploadSlip'
import SelectPayment from './StepPages/SelectPayment'

export default {
  name: 'CheckoutStep',
  metaInfo() {
    let title = 'Thai bike parts'
    let step = this.step
    switch (step) {
      case 2:
        title = 'Address'
        break
      case 3:
        title = 'Select Payment'
        break
    }
    return {
      title: `Thai bike parts`,
      titleTemplate: `${title} | %s`
    }
  },
  components: {
    Signin,
    Address,
    UploadSlip,
    Omise,
    Paypal,
    Loading,
    SelectPayment
  },
  data() {
    return {
      step: 1,
      paymentSelect: 'transaction',
      isLoading: false,
      addressDelivery: [],
      addressBilling: [],
      signupEmail: '',
      signupDetail: {
        password: '',
        firstname: '',
        lastname: ''
      },
      deliveryCost: 5
    }
  },
  created() {
    if (this.accessToken) {
      this.step = 2
      this.getDetailAddress(this.useAddressID)
    }
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      cartDetail: (state) => state.Cart.cartDetail,
      shippingCost: (state) => state.Address.shippingCost,
      accessToken: (state) => state.Auth.userDetail.access_token,
      statusCart: (state) => state.Cart.statusCart,
      useAddressID: (state) => state.Cart.statusCart.useAddressID
    })
  },
  methods: {
    increaseItem(item) {
      this.$store.dispatch('Cart/increaseOne', item)
    },
    decreaseItem(item) {
      this.$store.dispatch('Cart/decreaseOne', item)
    },
    removeItem(item) {
      this.$store.dispatch('Cart/removeItem', item)
    },
    payment(data) {
      this.paymentSelect = data
      this.step = 4
    },
    checkoutTopayment(data) {
      // ::TODO save State (ShipAddressID and CommentAddress) method = saveShipAddress

      this.$store.dispatch('Cart/saveShipAddress', data)
      this.getDetailAddress(data.useAddressID)
      this.step = 3
    },
    async getDetailAddress(addressID) {
      this.isLoading = true
      let res = await ServiceAPI.address.getDetailbyID({
        addressID: addressID,
        access_token: this.accessToken
      })
      this.isLoading = false
      if (res.status == 'success' && res.data.length !== 0) {
        this.addressDelivery = res.data.delivery
        this.addressBilling = res.data.billing
        this.$store.dispatch('Address/onSetBillingAddress', res.data.billing)
        await this.$store.dispatch('Address/onGetShippingCost', {
          cart_id: this.cartDetail.id,
          address_id: res.data.delivery.id
        })
      }
    },
    backToAddress() {
      if (this.accessToken) {
        this.step = 2
      }
    },
    backToSummary() {
      if (this.accessToken) {
        this.step = 3
      }
    }
  }
}
</script>

<style scoped>
.step-active {
  border-bottom: solid 2px var(--bkk-darkblue);
}
.layout-border {
  border: solid 2px #c4c4c4;
}
.layout-border-active {
  border: solid 2px var(--bkk-darkblue) !important;
}
</style>
